<template>
  <div class='district_top'>
    <district-map :class="show?'':'map'"/>
    <!-- <sound-manager /> -->
    <risk-push :class="show?'':'risk'"/>
    <online-rate :style="styleshow"/>
  </div>
</template>

<script>
import DistrictMap from './map.vue'
// import SoundManager from './soundManager.vue'
import RiskPush from './riskPush.vue'
import OnlineRate from './onlineRate.vue'
export default {
  components: { DistrictMap, RiskPush, OnlineRate },
  data () {
    return {
     styleshow:{
      display:'block'
     },
     show:true

    };
  },
  created () {
    if (JSON.parse(sessionStorage.getItem('vuex')).user.type) {
      this.styleshow.display='none';
      this.show=false
    }
    console.log(JSON.parse(sessionStorage.getItem('vuex')).user.type,'>>>>ld');

  },
  mounted () {

  },
  methods: {

  }
};
</script>

<style scoped lang="scss">
.district_top {
  display: flex;
  padding: 28px 10px 40px;
  justify-content: space-between;
  .risk{
    // width: 560px;
    padding:  0 80px;
  }
  .map{
    ::v-deep.district_map ul{
      padding-left: 70px;
    }
    ::v-deep .map_col{
      width: 900px;
      // transform: rotate(300);
    }
  }

}

</style>
