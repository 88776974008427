<template>
  <div class='online_rate'>
    <div class="district_headline">在线率</div>
    <div class="each_item">
      <div class="title">
        <i>
          <img src='../../../../assets/district/icon_left.png' alt="" />
        </i>
        <p>当前在线率</p>
        <span>
        </span>
      </div>
      <div class="content">
        <div class="bar">
          <div id="pieonline"></div>
          <div class="reminder">
            <div><span><i></i></span>当前在线率</div>
            <div>计算时段：<p>{{interval}}</p>
            </div>
          </div>
        </div>
        <div class="list">
          <h3>
            <img src="../../../../assets/district/line.png" alt="">
            当前离线酒店
          </h3>
          <div class="swiper swiper_offlineList">
            <div class="swiper-wrapper">
              <p class="swiper-slide" v-for="(el,index) in offlineList" :key="index">
                {{ el.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="each_item">
      <div class="title">
        <i>
          <img src='../../../../assets/district/icon_left.png' alt="" />
        </i>
        <p>在线率计算规则</p>
        <span>
        </span>

      </div>
      <div class="rule">
        <p>1、<span>计算周期为每月的26日至次月的25日。</span></p>
        <p>2、<span>酒店底数为该计算周期内，检测状态为开启的酒店数量。</span></p>
        <p>3、<span>如果酒店在统计周期内2天及以上有消毒记录，或者摄像头在线，该酒店即为在线。</span></p>
      </div>
    </div>

  </div>
</template>

<script>
import { getOnlineRate, getHotelOnLinRate, getAreaList } from '@/api'
import 'swiper/swiper.scss';
import Swiper, { Autoplay } from 'swiper';
Swiper.use([Autoplay])
export default {
  data () {
    return {
      offlineList: [],
      onlineRate: 0,
      interval: ''
    };
  },
  created () {

  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.offlineList = []
      const res = await getAreaList()
      const area = await res.data[0].adcode
      let time = this.$moment().format('yyyy-MM-DD')
      const date = this.$moment().format('DD')
      // console.log('date', typeof date, )
      if (Number(date) >= 26) {
        console.log(1);
        this.interval = `${this.$moment().subtract(1, 'month').format('MM')}.26~${this.$moment().format('MM')}.25`
        time = `${this.$moment().format('YYYY-MM')}-26`
      } else {
        console.log(5);
        this.interval = `${this.$moment().subtract(1, 'month').format('MM')}.26~${this.$moment().subtract(1, 'day').format('MM.DD')}`
        time = this.$moment().format('yyyy-MM-DD')
      }
      // console.log('area :>> ', area);
      getOnlineRate(area, time).then(res => {
        // console.log('res :>> ', res);
        const { data } = res
        let arr = Object.keys(data);
        if (arr.length > 0) {
          this.onlineRate = Math.round(data.onlineRate * 100)
          this.drawPie()
          if (data.hotelList && data.hotelList.length > 0) {
            this.offlineList = data.hotelList.filter(el => el.status === 3)
          }
        } else {
          getHotelOnLinRate(area, time).then(res => {
            // console.log('res :>> ', res);
            if (res.message === 'success') {
              getOnlineRate(area, time).then(res => {
                const { data } = res
                let arr = Object.keys(data);
                if (arr.length > 0) {
                  this.onlineRate = Math.round(data.onlineRate * 100)
                  this.drawPie()
                }
                if (data.hotelList && data.hotelList.length > 0) {
                  this.offlineList = data.hotelList.filter(el => el.status === 3)
                }
              })
            }
          })
        }

      })

      this.swiperInit()
      // })

    },
    drawPie () {
      const chart = this.$echarts.init(document.getElementById('pieonline'))
      // 绘制图表
      const option = {
        title: [{
          text: `${this.onlineRate}%`,
          x: '52%',
          y: '40%',
          textAlign: 'center',
          textStyle: {
            fontSize: '36',
            fontWeight: '700',
            color: '#FFFFFF',
            textAlign: 'center',
          },
        }],
        polar: {
          radius: ['80%', '60%'],
          center: ['52%', '50%'],
        },
        angleAxis: {
          max: 100,
          show: false,
        },
        radiusAxis: {
          type: 'category',
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,

          },
          axisTick: {
            show: false
          },
        },
        series: [
          {
            name: '',
            type: 'bar',
            roundCap: true,
            barWidth: 60,
            showBackground: true,
            backgroundStyle: {
              color: '#2e2856',
            },
            data: [this.onlineRate],
            coordinateSystem: 'polar',
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                  offset: 0,
                  color: '#0078FF'
                }, {
                  offset: 1,
                  color: '#7BE16E'
                }]),
              }
            }
          },

        ]
      };
      option && chart.setOption(option)
      window.addEventListener("resize", function () {
        chart.resize();
      })
    },
    swiperInit () {
      this.$nextTick(() => {
        if (this.offlineList && this.offlineList.length > 7) {
          new Swiper('.swiper_offlineList', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
        }
        if (this.offlineList && this.offlineList.length <= 7) {
          new Swiper('.swiper_offlineList', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            // loop: true,
            speed: 1500
          })
        }
      })
    }
  }
};
</script>

<style scoped lang="scss">
.online_rate {
  width: 560px;
  background: url("../../../../assets/district/border_2.png") no-repeat;
  background-size: 100% 100%;
  // .swiper_list {
  //   overflow: hidden;
  //   height: 170px;
  //   width: 90%;
  //   margin: 10px auto;
  //   .swiper-slide {
  //     padding: 0;
  //     height: 40px;
  //     color: white;
  //     text-align: center;
  //     .el-col {
  //       // text-align: center;
  //       // line-height: 40px;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       height: 100%;
  //     }
  //     .el-col-18 {
  //       // line-height: 40px;
  //       font-size: 14px;
  //     }
  //     .number {
  //       margin: 0 auto;
  //       border-radius: 50%;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       width: 24px;
  //       height: 24px;
  //       background-color: #999999;
  //       padding: 2px;
  //     }
  //   }
  //   .swiper-slide:nth-child(2n) {
  //     background-color: rgba(19, 56, 105, 0.6);
  //   }
  // }

  .each_item {
    margin-top: 10px;

    .title {
      display: flex;
      align-items: center;
      margin-left: 22px;

      i {
        display: block;
        width: 19px;
        height: 22px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      p {
        font-size: 16px;
        font-weight: bold;
        color: #55c6e6;
        margin: 0 7px;
      }

      span {
        display: block;
        width: 110px;
        height: 13px;
        background: url("../../../../assets/district/icon.png") no-repeat;
        background-size: cover;
        // img {
        //   width: 100%;
        //   height: 100%;
        // }
      }
    }
    .content {
      height: 250px;
      display: flex;
      width: 100%;
      .bar {
        width: 50%;
        height: 100%;
        box-sizing: border-box;
        padding-top: 10px;
        // position: relative;
        #pieonline {
          width: 100%;
          height: 76%;
        }
        .reminder {
          // position: absolute;
          width: 100%;
          // left: 50%;
          // margin-left: -40px;
          // bottom: 5%;
          > div {
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              display: block;
              width: 15px;
              height: 15px;
              background: linear-gradient(0deg, #0078ff 0%, #7be16e 100%);
              border-radius: 50%;
              position: relative;
              line-height: 1;
              margin-right: 4px;
              i {
                position: absolute;
                display: inline-block;
                line-height: 1;
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background: #00123b;
                left: 50%;
                margin-left: -5px;
                top: 50%;
                margin-top: -5px;
              }
            }
            p {
              color: #44caff;
            }
          }
          > div:last-child {
            margin-top: 8px;
          }
        }
      }
      .list {
        width: 50%;
        box-sizing: border-box;
        padding: 20px 30px 0 30px;
        h3 {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: bold;
          color: #facb15;
          letter-spacing: 2px;
          margin-bottom: 20px;
          img {
            width: 15px;
            height: 15px;
            margin-right: 6px;
          }
        }
        .swiper_offlineList {
          overflow: hidden;
          height: 182px;
          width: 100%;
          .swiper-slide {
            width: 100%;
            height: 26px;
            line-height: 26px;
            color: white;
            font-size: 16px;
            box-sizing: border-box;
            padding-left: 19px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .swiper-slide:nth-child(2n) {
            background-color: rgba(19, 56, 105, 0.6);
          }
        }
      }
    }
    .rule {
      border-radius: 10px;
      width: 88%;
      margin: 15px auto 0;
      box-sizing: border-box;
      padding: 8px 37px 8px 28px;
      background: rgba(91, 219, 246, 0.2);
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
      p {
        line-height: 30px;
        display: flex;
        align-items: flex-start;
        justify-content: left;
      }
    }
  }
}
</style>
