var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"district_map"},[_c('ul',[_c('li',[_vm._m(0),_c('p',[_vm._v(_vm._s(_vm.title)+"： "),_c('countTo',{attrs:{"startVal":0,"endVal":_vm.totalData.siteCount,"duration":2000,"separator":""}})],1)]),_c('li',[_vm._m(1),_c('p',[_vm._v("摄像头： "),_c('countTo',{attrs:{"startVal":0,"endVal":_vm.totalData.cameraCount,"duration":2000,"separator":""}})],1)]),_c('li',[_vm._m(2),_c('p',[_vm._v("插座： "),_c('countTo',{attrs:{"startVal":0,"endVal":_vm.totalData.boardCount,"duration":2000,"separator":""}})],1)]),_c('li',[_vm._m(3),_c('p',[_vm._v("消毒间： "),_c('countTo',{attrs:{"startVal":0,"endVal":_vm.totalData.roomCount,"duration":2000,"separator":""}})],1)]),_c('li',[_vm._m(4),_c('p',[_vm._v("运行天数： "),_c('countTo',{attrs:{"startVal":0,"endVal":_vm.totalData.runtime,"duration":2000,"separator":""}})],1)])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../../../assets/district/hotel.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../../../assets/district/camera.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../../../assets/district/socket.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../../../assets/district/sterilize.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../../../assets/district/operation.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map_col"},[_c('div',{attrs:{"id":"main"}})])
}]

export { render, staticRenderFns }