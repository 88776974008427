<template>
  <div class='push_statistics'>
    <div class="district_headline">一周提醒推送分类统计</div>
    <div class="swiper_statistics">
      <div class="swiper-wrapper">
        <div class="swiper-slide" :key="index" v-for="(el,index) in totalList">
          <div class="label">
            <span class="name"> {{el.name}}</span>
            <span class="count"> {{el.count}}</span>
          </div>
          <div class="statistics">
            <el-popover>
              <template slot="content">
                <div>
                  <p>{{el.name ? el.name : ''}}</p>
                  <p>{{el.count ? el.count : ''}}</p>
                </div>
              </template>
              <div slot="reference" :class="`item ${el.level === 1 ? 'itemOrg' : el.level === 2 ? 'itemPink' : el.level === 3 ? 'itemRed' : ''}`" :style="{width: `${el.percent.toFixed(2)}%` }">
              </div>
            </el-popover>
          </div>
        </div>

        <!-- {/* <div class="swiper-slide">
            <div class="label">台盆抹布使用不匹配</div>
            <div class="statistics">
              <Popover content={content} onVisibleChange={visible => this.handleVisibleChange(visible, 1)}>
                <div class="item" style={{ width: '50%' }}>
                </div>
              </Popover>
            </div>
          </div> */} -->
      </div>
    </div>
  </div>
</template>

<script>
import { getWeekRemind } from '@/api'
import 'swiper/swiper.scss';
import Swiper, { Autoplay } from 'swiper';
Swiper.use([Autoplay])
export default {
  data () {
    return {
      totalList: [
      ],
      maxNumber: 0,
      itemMsg: {}
    };
  },
  created () {

  },
  mounted () {
    this.getWeek()
  },
  methods: {
    getWeek () {
      getWeekRemind().then(res => {
        // if (res.data.message === 'success') {
        // console.log(res.data.data, 22222)
        const arr = JSON.parse(JSON.stringify(res.data.type))
        arr.sort((a, b) => {
          return b.count - a.count
        })
        // // console.log(arr)
        res.data.type.map(el => {
          el.percent = (el.count / arr[0].count) * 100
        })
        // console.log(res.data.data.type)
        this.totalList = res.data.type
        this.maxNumber = arr[0].count
        this.init()
      })

      // }
      // })
    },
    init () {
      this.$nextTick(() => {
        console.log('this,totalList :>> ', this.totalList);
        if (this.totalList && this.totalList.length > 5) {
          new Swiper('.swiper_statistics', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
        }
        if (this.totalList && this.totalList.length <= 5) {
          new Swiper('.swiper_statistics', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            // loop: true,
            speed: 1500
          })
        }
      })

    }
  }
};
</script>

<style scoped lang="scss">
.push_statistics {
  padding: 1px 0;
  width: 100%;
  background: url("../../../../assets/district/border.png") no-repeat;
  background-size: 100% 100%;
  height: 300px;
  .swiper_statistics {
    width: 80%;
    height: 240px;
    margin: 28px auto 0;
    overflow: hidden;
    .swiper-slide {
      width: 100%;
      height: 50px;
      .label {
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        margin-bottom: 10px;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .count {
          font-size: 14px;
        }
      }

      .statistics {
        position: relative;
        width: 95%;
        height: 18px;
        margin: 0 auto;
        background-color: transparent;
        background-color: rgba(98, 98, 98, 0.36);

        .item {
          position: absolute;
          bottom: 0;
          width: 50%;
          height: 100%;
          background-image: linear-gradient(
            90deg,
            rgba(139, 219, 249, 1),
            rgba(117, 218, 255, 0.8)
          );
          text-align: center;
          cursor: pointer;
        }

        .item::before {
          position: absolute;
          content: "";
          display: block;
          height: 100%;
          width: 9px;
          background-color: rgba(117, 218, 255, 1);
          border-radius: 50%;
          right: -4.5px;
          z-index: 1;
        }

        .item:after {
          position: absolute;
          content: "";
          display: block;
          height: 100%;
          width: 9px;
          border-radius: 50%;
          background-color: rgba(139, 219, 249, 1);
          left: -4.5px;
        }

        .itemPink {
          background-image: linear-gradient(
            90deg,
            rgba(247, 228, 250, 0.89),
            rgba(233, 165, 255, 0.8)
          );
        }

        .itemPink::before {
          background-color: rgba(233, 165, 255, 1);
        }

        .itemPink::after {
          background-color: rgba(247, 228, 250, 1);
        }

        .itemRed {
          background-image: linear-gradient(
            90deg,
            rgba(248, 102, 82, 0.938),
            rgba(244, 56, 6, 0.8)
          );
        }

        .itemRed::before {
          background-color: rgba(244, 56, 6, 1);
        }

        .itemRed::after {
          background-color: rgba(248, 102, 82, 1);
        }

        .itemOrg {
          background-image: linear-gradient(
            90deg,
            rgba(248, 153, 98, 0.938),
            rgba(245, 120, 2, 0.8)
          );
        }

        .itemOrg::before {
          background-color: rgba(245, 120, 2, 1);
        }

        .itemOrg::after {
          background-color: rgba(248, 153, 98, 1);
        }

        // .itemRed .itemOrg
      }

      .statistics:before {
        position: absolute;
        content: "";
        display: block;
        height: 100%;
        width: 9px;
        border-radius: 50%;
        right: -4.5px;
        z-index: 1;
        background-color: rgba(49, 49, 49, 1);
      }

      .statistics:after {
        position: absolute;
        content: "";
        display: block;
        height: 100%;
        width: 14px;
        border-radius: 50%;
        left: -9px;
        top: 0;
        // background-color: rgba(49, 49, 49, 1);
      }
    }
  }
}
</style>
