<template>
  <div class='sterilize_record'>
    <div class="district_headline">最近消毒记录</div>
    <el-row class="caption">
      <el-col :span="3">项目</el-col>
      <el-col :span="7"></el-col>
      <el-col :span="5">房间</el-col>
      <el-col :span="6">时间</el-col>
      <el-col :span="2">结果</el-col>
    </el-row>
    <div class="swiper swiper_recordList">
      <div class="swiper-wrapper">
        <el-row class="swiper-slide" v-for="(el,index) in rankList" :key="index">
          <el-col :span="3" :style="{ color: el.type === 1 ? '#FFE900' : el.type === 2 ? '#4F83FF' : el.type === 3 ? '#00C281' : '', fontWeight: 'bold' }">{{el.type === 1 ? '洁具洗消' : el.type === 2 ? '布草更换' : '杯具洗消'}}</el-col>
          <el-col :span="7">{{el.hotelName}}</el-col>
          <el-col :span="5" style="color: #C9A306" class="houseroom">{{el.roomId}}</el-col>
          <el-col :span="6" style="color: #C9A306">{{el.gmtCreate}}</el-col>
          <el-col :span="2" :style="{color: el.upstandardStatus? '#00A80A' : '#DC143C' }">{{el.upstandardStatus ? '合格' : '不合格'}}</el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { getSterilizeList } from '@/api'
import 'swiper/swiper.scss';
import Swiper, { Autoplay } from 'swiper';
Swiper.use([Autoplay])
export default {
  data () {
    return {
      rankList: []
    };
  },
  created () {

  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      const start = this.$moment().subtract(3, 'days').format("YYYY-MM-DD")
      const stop = this.$moment().subtract(1, 'days').format("YYYY-MM-DD")
      // console.log(startTime, endTime, 2222)
      getSterilizeList(start, stop).then(res => {
        // if (res.data.message === 'success') {
        // this.setState({
        this.rankList = res.data
        // const arr = res.data.data.filter(el => el.type === 3 && !el.upstandardStatus)
        // console.log(arr, 4444)
        this.initSwiper()
        // }
      })
    },
    initSwiper () {
      this.$nextTick(() => {
        if (this.rankList && this.rankList.length > 6) {
          new Swiper('.swiper_recordList', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
        }
        if (this.rankList && this.rankList.length <= 6) {
          new Swiper('.swiper_recordList', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            // loop: true,
            speed: 1500
          })

        }
      })

    }
  }
};
</script>

<style scoped lang="scss">
.sterilize_record {
  padding: 1px 0;
  width: 100%;
  background: url("../../../../assets/district/border_1.png") no-repeat;
  background-size: 100% 100%;
  height: 300px;
  .caption {
    color: #2b9bd1;
    font-size: 14px;
    text-align: center;
    width: 97%;
    margin: 10px auto 0;
    height: 36px;

    // .el-col {
    //   height: 36px;
    // }

    // .el-col-7 {
    //   background: none !important;
    //   height: 36px !important;
    // }
  }
  .el-col {
    border: 1px solid transparent;
  }
  .swiper_recordList {
    // width: 100%;
    overflow: hidden;
    height: 220px;
    width: 97%;
    // margin-top: .625rem;

    .swiper-slide {
      height: 48px;
      line-height: 48px;
      color: white;
      text-align: center;

      .el-col {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }

      .el-col-7 {
        line-height: 22px;
        background: none !important;
        overflow: hidden;

        white-space: nowrap;

        text-overflow: ellipsis;
        justify-content: flex-start;
      }
    }

    .swiper-slide:nth-child(2n) {
      background-color: rgba(19, 56, 105, 0.6);
    }
  }
}
</style>
