<template>
  <div class='sterilize_video'>
    <div class="district_headline">最近消毒识别视频</div>
    <div class="video" v-if="activeVideo">
      <video :src="name==='上城区'?'https://iva.terabits.cn/mnt/detection/resource/sc.mp4':name=='萧山区'?'https://statics.terabits.cn/video/xs.mp4':`https://iva.terabits.cn${activeVideo}`" id="myvideoback" width="90%" height="90%" controls autoPlay setAttribute="crossorigin" muted>
        <!-- <source src="name==='上城区'?'https://iva.terabits.cn/mnt/detection/resource/sc.mp4':`https://iva.terabits.cn${activeVideo}`" type="video/mp4"> -->
      </video>
    </div>

  </div>
</template>

<script>
import { getSterilizeVideo } from '@/api'
import videojs from 'video.js';

export default {
  data () {
    return {
      videoList: [],
      activeVideo: '',
      videoIndex: 0,
      videourl: null,
      playNum: 0,
      isShow: true,
      name: ''
    };
  },
  created () {

  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      getSterilizeVideo().then(res => {
        // if (res.data.message === 'success') {
        this.videoList = res.data
        this.activeVideo = res.data[5]
        this.name = localStorage.getItem('areaTitle')
        // console.log('this.name :>> ', this.name);
        // })
        this.playBack()
      })
    },
    playBack () {
      this.$nextTick(()=>{
        let myvid = document.getElementById('myvideoback')
         let index = this.videoIndex
     console.log(myvid);
      let videoPlay = myvid.play()
      videoPlay.then(() => {
        console.log('可以自动播放');
      }).catch((err) => {
        console.log(err)
        console.log("不允许自动播放")
        myvid.muted = true;
        myvid.play();
      });
      const _this = this
      myvid.addEventListener('ended', function () {
        // update the new active video index
        index += 1
        if (index === _this.videoList.length - 1) {
          index = 0
        }
        // _this.setState({
        _this.activeVideo = _this.videoList[index]
        _this.videoIndex = index
        // })
        myvid.play();
      });
      })
      
    },
    play (props) {
      const _this = this
      // const { playNum, isShow } = this.state
      const player = videojs('myvideoback', props, function onPlayerReady () {
        // console.log('onPlayerReady', this);
        this.on('error', function () {
          // this.dispose()
          _this.init()
        })
      });
      player.play();
    }
  }
};
</script>

<style scoped lang="scss">
.sterilize_video {
  padding: 1px 0;
  width: 100%;
  background: url("../../../../assets/district/border_4.png") no-repeat;
  background-size: 100% 100%;
  height: 300px;
  .video {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    height: 95%;
  }
}
</style>
