<template>
  <el-row class='district_bottom' :gutter="6">
    <el-col :span="7">
      <push-statistics />
    </el-col>
    <el-col :span="10">
      <sterilize-record />
    </el-col>
    <el-col :span="7">
      <sterilize-video />
    </el-col>
  </el-row>
</template>

<script>
import PushStatistics from './pushStatistics.vue'
import SterilizeRecord from './sterilizeRecord.vue'
import SterilizeVideo from './sterilizeVideo.vue'
export default {
  components: { PushStatistics, SterilizeRecord, SterilizeVideo },
  data () {
    return {

    };
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
};
</script>

<style scoped lang="scss">
.district_bottom {
  display: flex;
  padding: 0 10px;
}
</style>
