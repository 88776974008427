<template>
  <div class='district'>
    <Header />
    <top />
    <bottom />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Top from './components/top'
import Bottom from './components/bottom'
export default {
  components: { Header, Top, Bottom },
  data () {
    return {

    };
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
};
</script>

<style scoped lang="scss">
.district {
  width: 100%;
  min-height: 100vh;
  background: url("../../assets/district/bg.png") no-repeat;
  background-size: 100% 100%;
}
</style>
