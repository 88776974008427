<template>
  <div class="header">
    <div class="left">
      <div>
        <p>{{title}}放心住宿卫生监管在线</p>
        <i></i>
      </div>
    </div>
    <div class="right">
      <div class="goback" @click="goback()">
        <i class="el-icon-caret-left" style="color:#FFD614"></i>
        <span>返回平台</span>
      </div>
      <div class="time">
        <p>{{$moment(time).format('HH:mm:ss')}}</p>
        <p>{{$moment().format('YYYY-MM-DD')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
let interval
import { getAreaList } from '@/api'
import { environment } from '../../../utils/config'
export default {
  data () {
    return {
      time: this.$moment(),
      token: this.$store.getters.token,
      title: ''
    };
  },
  created () {
    interval = setInterval(() => this.time = new Date(), 1000)
    this.init()
  },
  mounted () {

  },
  methods: {
    init () {
      getAreaList().then(res => {
        const name = res.data[0].name.split(',')
        if (name[name.length - 1] === '莲都区') {
          localStorage.setItem('type', 'ld')
          localStorage.setItem('hoteltitle', '民宿')
        } else {
          localStorage.setItem('hoteltitle', '酒店')
          localStorage.setItem('type', '')
        }
        document.title = `${name[name.length - 1]}放心住宿卫生监管在线`
        localStorage.setItem('areaTitle', name[name.length - 1])
        // localStorage.setItem('areaCode', res.data[0].adcode)
        this.title = name[name.length - 1]
      })
    },
    goback () {
      // const type = localStorage.getItem('type')
      // const IP = environment === 'TE' ? 'https://ivatest.terabits.cn' : 'https://zjiva.terabits.cn'
      const IP = environment === 'DE' ? 'https://ivatest.terabits.cn' : 'https://zjiva.terabits.cn'
      // if (type) {
      //   window.open(`http://ldiva.terabits.cn/app/areastatistics?token=${this.token}`)
      // } else {
      window.open(`${IP}/app/areastatistics?token=${this.token}`)
      // }
    }
  },
  destroyed () {
    clearInterval(interval)
  }
};
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  height: 58px;
  background: linear-gradient(
    0deg,
    rgba(16, 86, 163, 0.98),
    rgba(33, 83, 143, 0.05)
  );
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 255, 255, 0.8);

  .left {
    width: 614px;
    height: 71px;
    box-sizing: border-box;
    padding: 1px;
    background-image: linear-gradient(0deg, #00ffea, rgba(33, 83, 143, 0.3));
    border-bottom-right-radius: 71px;
    position: relative;

    div {
      width: 100%;
      height: 100%;
      background: linear-gradient(
        0deg,
        rgba(16, 86, 163, 0.98),
        rgba(33, 83, 143, 0.05)
      );
      border-bottom-right-radius: 71px;
      display: flex;
      align-items: center;

      p {
        margin-left: 6px;
        font-size: 33px;
        font-weight: bold;
        margin-left: 20px;
        color: #ffffff;
        white-space: nowrap;
        letter-spacing: 2px;
      }

      i {
        position: absolute;
        bottom: 0;
        left: 92px;
        display: block;
        width: 131px;
        border-bottom: 4px solid #00ffff;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        height: 0;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #29ddfe;

    .goback {
      margin-right: 55px;
      cursor: pointer;

      span {
        margin-left: 10px;
      }
    }

    .time {
      font-family: Furore;
      font-weight: 400;
      margin-right: 40px;

      p:first-child {
        margin-bottom: 7px;
      }

      p:last-child {
        font-size: 12px;
      }
    }
  }
}
</style>
